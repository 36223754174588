
import Navigation from '@/components/Navigation.vue';
import AppGrid from '@/components/AppDisplay/AppGrid.vue';
import AppCard from '@/components/AppDisplay/AppCard.vue';
import { Paths, generateDynamicPath } from '@/router/Paths';
import { Applications } from '@/router/Applications';
import { defineComponent } from 'vue';

export default defineComponent({
  setup() {
    return {
      Paths,
      Applications,
      generateDynamicPath
    };
  },
  components: { Navigation, AppGrid, AppCard },
});
